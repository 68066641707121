export const actionCreator = function(type){
	return function(payload = null, meta = null, error = null){
		const action = { type };
		if(payload !== null)
			action['payload'] = payload;
		
		if(error !== null)
			action['error'] = error;
		
		if(meta !== null)
			action['meta'] = meta;
	
		return action;
	};
};