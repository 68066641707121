import { actionCreator } from './_actionCreator';

export const SET_ENABLED = "SET_ENABLED";
export const SET_ENDPOINT = "SET_ENDPOINT";

export const setEnabled = actionCreator(SET_ENABLED);
export const setEndpoint = actionCreator(SET_ENDPOINT);

const initialState = {
	enabled : false,
	endpoint : ""
};

const reducer = function(state = initialState, action){
	switch(action.type){
		case SET_ENABLED:
			return {...state, enabled : action.payload};
		case SET_ENDPOINT:
			return {...state, endpoint : action.payload};
		default:
			return state;
	}
};

export const pusher = {persist : true, reducer};