export class Request{
	
	constructor(request, controller){
		this.request = request;
		this.controller = controller;
	}
	
	then(...args){
		return this.request.then(...args);
	}
	
	catch(...args){
		return this.request.catch(...args);
	}
	
	abort(){
		this.controller.abort();
	}
}