import { actionCreator } from './_actionCreator';

const initialState = 0;

export const INCREMENT_KEY = "INCREMENT_KEY";
export const DECREMENT_KEY = "DECREMENT_KEY";

export const requestStart = actionCreator(INCREMENT_KEY);
export const requestStop = actionCreator(DECREMENT_KEY);

const reducer = function(state = initialState, action){
	switch(action.type){
		case INCREMENT_KEY:
			return state + 1;
		case DECREMENT_KEY:
			return state - 1;
		default:
			return state;
	}
};

export const http = { persist : false, reducer };