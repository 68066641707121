import React/*, { useCallback }*/ from 'react';
import './Header.css';
//import { useSelector, useDispatch } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { toggleNavbar } from '../../Store/actions/navbar';
//import Media from 'react-media';
import { DropDown } from '../DropDown/DropDown';
import { DropDownMenu } from '../DropDown/DropDownMenu';
import { DropDownMenuLink } from '../DropDown/DropDownMenuLink';
import { DropDownMenuItem } from '../DropDown/DropDownMenuItem';
import { Auth } from '../../Lib/Auth';

library.add(faBars);
library.add(faCaretDown);
library.add(faUserCog);

export const Header = React.memo(function(){

	//const isLogged = useSelector(state => state.auth.logged);

	//const dispatch = useDispatch();

	//const toggle = useCallback(() => dispatch(toggleNavbar()) , [dispatch]);

	let firstElement;
	let lastElement;

	//if(isLogged){
		firstElement = <span></span>;

		lastElement = (<DropDown
			trigger={<button className="myuser">
						<FontAwesomeIcon icon="user-cog" size="2x"/>
						<FontAwesomeIcon icon="caret-down"/>
					</button>}
			content={
				<DropDownMenu>
					<DropDownMenuLink to="/miusuario" text="menu.myuser"/>
					<DropDownMenuItem onClick={() => Auth.logout()} text="logout"/>
				</DropDownMenu>

			}/>)
	/*}else{
		firstElement = <Image alt="app.name" src={logo}/>;
	}*/

	return (
		<div className="header">
			<div className="header-wrap">
				{ firstElement }
				{ lastElement }
			</div>
		</div>
	);
});
