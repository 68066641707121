import React from 'react';
import './FormCard.scss';

export const FormCard = React.memo(function({header, children}){
	return (
		<div className="form-card">
			{ (header !== void 0) && <div className="form-title">
				{header}
			</div>
			}
			<div className="form-body">
				{children}
			</div>
		</div>
	);
});