import { actionCreator } from './_actionCreator';

export const AUTH_LOGGED = "AUTH_LOGGED";
export const AUTH_USER = "AUTH_USER";
export const AUTH_EXPIRES = "AUTH_EXPIRES";

export const setLogged = actionCreator(AUTH_LOGGED);
export const setUser = actionCreator(AUTH_USER);
export const setExpires = actionCreator(AUTH_EXPIRES);

const initialState = {
	logged : false,
	user : {},
	expires : 0
};

const reducer = function(state = initialState, action){
	switch(action.type){
		case AUTH_LOGGED:
			return {...state, logged : action.payload};
		case AUTH_USER:
			return {...state, user : action.payload};
		case AUTH_EXPIRES:
			return {...state, expires : action.payload};
		default:
			return state;
	}
};

export const auth = {
	persist : true,
	reducer
};