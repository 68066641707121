import { useCallback, useEffect } from "react";

export default function useOutsideAlerter(ref, callback, active) {
	
	const handleClickOutside = useCallback((event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			callback();
		}
	}, [ref, callback]);
	
	useEffect(() => {
		if(!active)
			return;
		
		const cb = handleClickOutside;
		
		document.addEventListener("mousedown", cb);
		
		return () => {
			document.removeEventListener("mousedown", cb);
		};
		
	}, [handleClickOutside, active]);
};