export class Response{
	
	constructor(val, response){
		if(typeof val == "object"){
			for(const key in val)
				this[key] = val;
			return;
		}
		
		this.success = val;
		if(!val){
			this.mes = response;
			return;	
		}
		
		if(Array.isArray(response))
			this.items = response;
		else
			this.item = response;
	}
	
}