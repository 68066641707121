import React, { useCallback } from 'react';
import './ErrorScreen.scss';
import { useHistory } from "react-router-dom";
import { Text } from '../Text/Text';

const _ErrorScreen = ({title, description, action, onClick}) => {
	
	const history = useHistory();
	
	const mClick = useCallback((e) => {
		if(onClick !== void 0){
			onClick(e);
			return;
		}
		
		history.push('/');
		
	}, [onClick, history]);
	
	return (
		<div className="error-screen">
			<h1>
				<Text text={title}/>
			</h1>
			<div className="error-line">
			</div>
			<p>
				<Text text={description}/>
			</p>
			<button className="btn btn-outline-light btn-lg" onClick={mClick}>
				<Text text={action}/>
			</button>
		</div>
	);
};

export const ErrorScreen = React.memo(_ErrorScreen);