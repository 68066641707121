import { actionCreator } from './_actionCreator';

export const SET_DARK = "SET_DARK";
export const SET_LIGHT = "SET_LIGHT";
export const SET_COLOR = "SET_COLOR";

export const setDark = actionCreator(SET_DARK);
export const setLight = actionCreator(SET_LIGHT);
export const setColor = actionCreator(SET_COLOR);

const initialState = {
	color : "light",
	space : "normal"
};

const reducer = function(state = initialState, action){
	switch(action.type){
		case SET_DARK:
			return {...state, color : "dark"};
		case SET_LIGHT:
			return {...state, color : "light"};
		case SET_COLOR:
			return {...state, color : action.payload};
		default:
			return state;
	}
};

export const theme = {persist : true, reducer};
