import { actionCreator } from './_actionCreator';

const initialState = {};

export const SET_KEY = "SET_DICT";
export const SET_MANYKEYS = "SET_MANYKEYS";
export const DELETE_DICT = "DELETE_DICT";
export const DELETE_MANYKEYS = "DELETE_MANYKEYS";

export const setDict = actionCreator(SET_KEY);
export const setManyKeys = actionCreator(SET_MANYKEYS);
export const deleteManyKeys = actionCreator(DELETE_MANYKEYS);
export const deleteDict = actionCreator(DELETE_DICT);

const reducer = function(state = initialState, action){
	switch(action.type){
		case SET_MANYKEYS:
			const newStateSet = {...state};
			
			for(const key in action.payload){
				newStateSet[key] = action.payload[key];
			}
			
			return newStateSet;
			
		case SET_KEY:
			return {...state, [action.meta] : action.payload};
		
		case DELETE_DICT:
			const s = {...state};
			delete s[action.payload];
			return s;
			
		case DELETE_MANYKEYS:
			const newStateDelete = {...state};
			
			for(const key of action.payload){
				delete newStateDelete[key];
			}
			
			return newStateDelete;
	
		default:
			return state;
	}
};

export const dictionary = {persist : false, reducer};