import React from "react";
import loadable from "@loadable/component";

const sidebarSkellyStyle = {
	height : "100%",
	width: "60px",
	position : "absolute",
	top : "0",
	left : "0",
	display : "flex"
}

const SidebarSkelly = () => <div style={sidebarSkellyStyle} className="ghostLoad"></div>;

export const Sidebar = loadable(() => import("./_Sidebar"), { fallback : <SidebarSkelly/> });
