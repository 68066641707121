import React from 'react';
import loadable from '@loadable/component';
import { timeout } from 'promise-timeout';
import { ErrorScreen } from '../ErrorScreen/ErrorScreen';

const makeLoadable = component => loadable(() => timeout(component(), 45 * 1000));

export class LazyLoader extends React.PureComponent{
	
	constructor(props) {
		super(props);
		this.state = { hasError: false };
		this.resetError = () => this._resetError.call(this);
	}
	
	static getDerivedStateFromError(error) {
		if(error.name === 'ChunkLoadError' || error.name === 'TimeoutError')
			return {hasError : true};
	}
	
	_resetError(){
		this.setState({hasError : false});
	}
	
	render() {
		if (this.state.hasError)
			return <ErrorScreen title={"loader.error.title"} 
					description={"loader.error.description"} 
					action={"loader.error.action"} 
					onClick={this.resetError}/>;
		
		const {component, fallback} = this.props;
		
		const Loadable = makeLoadable(component); 
		
		return <Loadable fallback={fallback}/>;
	}
};