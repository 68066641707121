import React from 'react';
import "./SkeletonBlock.css";

const _SkeletonBlock = ({height, width}) => (
	<span className="ghostLoad skeletonBlock" style={{height, width}}>
	</span>
);

_SkeletonBlock.defaultProps = {
	height : '14px',
	width : '100%'
};

export const SkeletonBlock = React.memo(_SkeletonBlock);