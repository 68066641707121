import { actionCreator } from './_actionCreator';
import Cookies from 'js-cookie';

export const SET_LANG = "SET_LANG";

export const setLang = actionCreator(SET_LANG);

const initialState = "es";

const reducer = function(state = initialState, action){
	switch(action.type){
		case SET_LANG:
			Cookies.set('lang', action.payload, { expires : 365 });
			return action.payload;
		default:
			return state;
	}
};

export const lang = {persist : true, reducer};