import React, { useEffect, useState, useCallback } from 'react';
import './App.scss';
import '../Forms/Forms.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Sidebar } from '../Sidebar/Sidebar';
import { Overlay } from '../Sidebar/Overlay';
import { Header } from '../Header/Header';
import { MainContent } from '../../BusinessLogic/MainContent/MainContent';
import { MenuLinks } from '../../BusinessLogic/MenuLinks/MenuLinks';
import { useHistory } from 'react-router-dom';
import 'moment/locale/es';
import config from '../../config';
import { setEndpoint } from '../../Store/actions/pusher';
import { Auth } from '../../Lib/Auth';
import { setLocation } from '../../Lib/HTTPClient';
import { HeroCard } from '../../Components/HeroCard/HeroCard';
import { HeroImage } from '../../Components/HeroImage/HeroImage';
import { Text } from '../../Components/Text/Text';

const positionErrors = {
  1: (
    <Text text="Parece que has rechazado la solicitud de permiso, debes hacer click en el candado de la barra de direccion y habilitar la geolocalizacion" />
  ),
  2: <Text text="geolocation.error.internal" />,
  3: <Text text="geolocation.error.timedout" />,
};

const InActiveApp = function ({ errorMessage }) {
  return (
    <div className="main-content">
      <HeroImage>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <HeroCard header={<Text text="activate.geolocation.title" />}>
                <Text text="activate.geolocation.body" />
                <br />
                <b>{errorMessage && positionErrors[errorMessage.code]}</b>
              </HeroCard>
            </div>
          </div>
        </div>
      </HeroImage>
    </div>
  );
};

const ActiveApp = React.memo(function ({ isLogged }) {
  return (
    <>
      {isLogged && (
        <>
          <Sidebar>
            <MenuLinks />
          </Sidebar>
          <Header />
        </>
      )}

      <Overlay />
      <MainContent />
    </>
  );
});

const App = React.memo(function () {
  const [hasGeo, setHasGeo] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handlePosition = useCallback(function ({
    coords: { latitude, longitude },
  }) {
    setHasGeo(true);
    setErrorMessage(false);
    setLocation(latitude, longitude);
  },
  []);

  const handleError = useCallback(function (error) {
    setErrorMessage(error);
    setHasGeo(false);
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(handlePosition, handleError, {
        enableHighAccuracy: true,
      });
    }
  }, [handlePosition, handleError]);

  const history = useHistory();

  const isLogged = useSelector(state => state.auth.logged);
  const pusher = useSelector(state => state.pusher);
  const [pusherDone, setPusherDone] = useState(false);
  const [doingPusher, setDoingPusher] = useState(false);
  const [initialAuth, setInitialAuth] = useState(isLogged);
  const dispatch = useDispatch();

  useEffect(() => {
    if (doingPusher || !isLogged) return;

    if (pusher.enabled) {
      if (pusherDone) return;

      setDoingPusher(true);

      navigator.serviceWorker.ready
        .then(reg =>
          reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: config.VAPIDPublicKey,
          })
        )
        .then(sub => {
          dispatch(setEndpoint(sub.endpoint));
          return Auth.updatePusherEndpoint(sub.toJSON());
        })
        .then(r => {
          setPusherDone(true);
          setDoingPusher(false);
        })
        .catch(e => {
          setDoingPusher(false);
        });
    } else if (pusher.endpoint.length > 1) {
      setDoingPusher(true);

      Auth.unsubPusher(pusher.endpoint)
        .then(() => {
          dispatch(setEndpoint(''));
          setDoingPusher(false);
          setPusherDone(false);
        })
        .catch(e => {
          dispatch(setEndpoint(''));
          setDoingPusher(false);
          setPusherDone(false);
        });
    }
  }, [pusherDone, doingPusher, pusher, isLogged, dispatch]);

  const theme = useSelector(state => state.theme.color);

  useEffect(() => {
    if (initialAuth && !isLogged) history.push('/');

    setInitialAuth(isLogged);
  }, [initialAuth, isLogged, history]);

  return (
    <div className={`App theme-${theme}`}>
      <div className="headwrap">
        <div className="headboard"></div>
      </div>
      <div className={`app-container${isLogged ? ' logged' : ''}`}>
        {hasGeo ? (
          <ActiveApp isLogged={isLogged} />
        ) : (
          <InActiveApp errorMessage={errorMessage} />
        )}
      </div>
    </div>
  );
});

export default App;
