import React, {useCallback} from 'react';
import { useDispatch } from 'react-redux';
import Media from 'react-media';
import { toggleNavbar } from '../../Store/actions/navbar';

export const Overlay = React.memo(() => {

    const dispatch = useDispatch();
	const toggle = useCallback(() => dispatch(toggleNavbar()) , [dispatch]);

    return <Media query="(max-width : 768px)">
        <div className="overlay" onClick={toggle}></div>
    </Media>;
});
