import React from 'react';
import './HeroCard.scss';

export const HeroCard = React.memo(function({header, children}){
	return (
		<div className="hero-card">
			<div className="hero-title">
				<h5> { header } </h5>
			</div>
			<div className="hero-body">
				{children}
			</div>
		</div>
	);
});
