import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from '../Lib/Lang';

const langs = {};

export const useLangArr = function(args){
	const lang = useSelector(state => state.lang);
	const [text, setText] = useState(args);
	const [keys, setKeys] = useState(args);

	useEffect(() => {
		if(args.length !== keys.length){
			setKeys(args);
			return;
		}

		for(const key in args){
			if(args[key] !== keys[key]){
				setKeys(args);
				return;
			}

		}

	}, [args, keys])

	useEffect(() => {
		if(keys.length === 0)
			return;

		if(lang in langs){
			let found = 1;
			let newText = [];
			for(const index in keys){
				if(keys[index] in langs[lang]){
					newText[index] = langs[lang][keys[index]];
					found++;
				}else{
					newText[index] = keys[index];
				}
			}

			if(found === keys.length){
				setText(newText);
				return;
			}
		}

		let cancel = false;

		getLang(lang)
		.then(langAsset => {
			if(cancel) return;

			langs[lang] = langAsset;
			let update = false;
			let newText = [];
			for(const key of keys){
				if(key in langAsset){
					update = true;
					newText.push(langAsset[key]);
				}else{
					newText.push(key);
				}
			}
			
			if(update)
				setText(newText);
		})
		.catch((e) => {});

		return () => { cancel = true; };
	}, [lang, keys]);

	return text;
};

export default function useLang(key){

	const lang = useSelector(state => state.lang);

	if(lang in langs){
		if(key in langs[lang]){
			key = langs[lang][key];
		}
	}

	const [notFound, setNotFound] = useState(false);
	const [text, setText] = useState(key);

	useEffect(() => {
		setText(key);
		setNotFound(false);
	}, [lang, key]);

	useEffect(() => {
		if(text == null || text === void 0 || notFound) return;

		let cancel = false;

		getLang(lang)
		.then(langAsset => {
			if(cancel) return;

			langs[lang] = langAsset;
			if(text in langAsset){
				setText(langAsset[text]);
			}else{
				setNotFound(true);
			}
		})
		.catch((e) => {});

		return () => { cancel = true; };
	}, [lang, text, notFound]);

	return text;
};
