import React from "react";
import loadable from "@loadable/component";

const itemSkellyStyle = {
	display: "list-item",
	color: "#fff",
	height : "35px",
	cursor: "pointer",
	padding: ".17857rem 0",
	margin: ".35714rem 0",
	borderRadius: "20px",
	position: "relative",
};

const MenuLinksSkelly = () => <>
	<div style={itemSkellyStyle} className="ghostLoad"></div>
	<div style={itemSkellyStyle} className="ghostLoad"></div>
	<div style={itemSkellyStyle} className="ghostLoad"></div>
	<div style={itemSkellyStyle} className="ghostLoad"></div>
</>;

export const MenuLinks = loadable(() => import("./_MenuLinks"), { fallback : <MenuLinksSkelly/> });