import { useRef, useEffect } from 'react';

function createRootElement(id) {
	const rootContainer = document.createElement('div');
	rootContainer.setAttribute('id', id);
	return rootContainer;
}

function addRootElement(rootElem) {
	document.body.insertBefore(
		rootElem,
		document.body.lastElementChild.nextElementSibling,
	);
}

export default function usePortal(id) {
	const rootElemRef = useRef(null);

	useEffect(() => {
		
		const existingParent = document.querySelector(`#${id}`);
		
		const parentElem = existingParent || createRootElement(id);

		if (!existingParent) {
			addRootElement(parentElem);
		}

		// Add the detached element to the parent
		parentElem.appendChild(rootElemRef.current);

		return function removeElement() {
			rootElemRef.current.remove();
			if (parentElem.childNodes.length === 0) {
				setTimeout(() => {
					parentElem.remove();
				}, 500);
			}
		};
	}, [id]);
	
	function getRootElem() {
		if (!rootElemRef.current) {
			rootElemRef.current = document.createElement('div');
		}
		return rootElemRef.current;
	}

	return getRootElem();
}