import React, { useState, useEffect, useCallback } from 'react';
import './Modal.scss';
import { createPortal } from 'react-dom';
import usePortal from '../../Hooks/usePortal';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const Modal = React.memo(function({children, outCallback, iconButton}){
	const target = usePortal('modal-root');
	const theme = useSelector(state => state.theme.color);
	const [isIn, setIn] = useState(false);

	useEffect(() => {

		const timer = setTimeout(() => {
			setIn(true);
		}, 100);

		return () => {
			clearTimeout(timer);
			setIn(false);
		};
	}, []);

	const onClick = useCallback(() => {

		if(outCallback === void 0) return;

		setIn(false);
		setTimeout(() => {
			outCallback();
		},510);

	}, [outCallback]);

	return createPortal(
		(
		<div className={`modal-container${isIn ? ' in' : ''}`}>
			<div className={`modal-fade theme-${theme}`} onClick={onClick}></div>
			<div className={`modal-overlay theme-${theme}`} >

				<div className="container-fluid py-5">
					<div className="modal-content">
					{iconButton !== void 0 &&<div className="close-button" onClick={onClick}>
						<FontAwesomeIcon icon={iconButton} size="lg"/>
					</div>}
						{ children }
					</div>
				</div>
			</div>
		</div>),
		target
	);
});
