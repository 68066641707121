import PropTypes from 'prop-types';
import React from 'react';
import useLang from '../../Hooks/useLang';
import { SkeletonBlock } from '../SkeletonBlock/SkeletonBlock';

const _Text = function({text}){
	return useLang(text) || <SkeletonBlock width={'120px'}/>;
};

_Text.propTypes = {
	text: PropTypes.string.isRequired
};

export const Text = React.memo(_Text);