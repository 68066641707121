import { actionCreator } from './_actionCreator';

export const SET_LIMIT = "SET_LIMIT";

export const setLimit = actionCreator(SET_LIMIT);

const initialState = { limit : 20 };

const reducer = function(state = initialState, action){
	switch(action.type){
		case SET_LIMIT:
			return {...state, limit : parseInt(action.payload)};
		default:
			return state;
	}
};

export const pagination = {persist : true, reducer};