import { BroadcastChannel } from 'broadcast-channel';
import { addRawResponseHook, addSuccessResponseHook, API } from '../API';
import config from '../../config';
import store from '../../Store';
import { setLogged, setUser, setExpires } from '../../Store/actions/auth';

class _Auth{

	_onSuccessResponse(response){
		if('expires' in response)
			this._saveLogin(parseInt(response.expires) * 1000);
	}

	_onRawResponse(response){
		if(this.isLogged() && response.status === 401)
			this.logout();
	}

	_saveLogin(expires = 0){
		this.setExpires(expires);
		this.requestUser();
	}

	_setLogoutTimer(){
		if(this.timerID)
			clearTimeout(this.timerID);

		this.timerID = setTimeout( () => {
			this.logout();
		}, Date.now() - this._expires);
	}

	constructor(){
		this.channel = new BroadcastChannel('auth_channel', { webWorkerSupport : false });
		this.channel.addEventListener('message', message => {
			switch(message.action){
				case 'expire':
					this._setExpires(message.payload);
					break;
				case 'user':
					this._setUser(message.payload);
					break;
				case 'login':
					this._emitLogin();
					break;
				case 'logout':
					this._logout();
					this._emitLogout();
					break;
				default:
					break;
			}
		});

		this.authApi = new API(config.endpoints.auth, true);
		this.userApi = new API(config.endpoints.fct_users, true);

		addRawResponseHook((r) => this._onRawResponse(r));
		addSuccessResponseHook((r) => this._onSuccessResponse(r));

		const authState = store.getState().auth;
		this._expires = authState.expires;
		this._user = authState.user;

		const isLogged = this.isLogged();

		store.dispatch(setLogged(isLogged));

		this.loginHooks = new Set();
		this.userHooks = new Set();

		if(isLogged){
			const u = localStorage.getItem(`${config.authPrefix}_user`);
			if(u != null && u.length > 0){
				this.setUser(JSON.parse(u));
			}
			this.requestUser();
			this._setLogoutTimer();
		}else{
			this.deleteUser();
			this.deleteExpires();
		}
	}

	updatePusherEndpoint(pusherSub){
		if(this.updatingPusher)
			this.updatingPusher.abort();

		this.updatingPusher = this.authApi._post('/subscribe', {}, pusherSub);

		return this.updatingPusher
		.request.then(response => {
			delete this.updatingPusher;

			if(!response.success)
				return response;

			return response;
		});
	}

	unsubPusher(endpoint){
		if(this.unsubingPusher)
			this.unsubingPusher.abort();

		this.unsubingPusher = this.authApi._post('/unsubscribe', {}, {endpoint});

		return this.unsubingPusher
		.request.then(response => {
			delete this.unsubingPusher;

			if(!response.success)
				return response;

			return response;
		});
	}

	restorePassword(password, captcha, jwt){
		if(this.restoring)
			this.restoring.abort();

		this.restoring = this.authApi._post('/restorepassword', {}, { password, captcha, jwt });

		return this.restoring.then(response => {
			delete this.restoring;

			if(!response.success)
				return response;

			return response;
		});
	}

	requestPassword(mail, captcha){
		if(this.requesting)
			this.requesting.abort();

		this.requesting = this.authApi._post('/requestpassword', {}, { mail, captcha });

		return this.requesting.then(response => {
			delete this.requesting;

			if(!response.success)
				return response;

			return response;
		});
	}

	isLogged(){
		return this.getExpires() > Date.now();
	}

	isAdmin(){
		if(!this.isLogged() || !this._user.hasOwnProperty('type')) return;

		return this._user.type === 1;
	}

	requestUser(){
		this.userApi.getSelf()
		.then(r => {
			if(!r.success)
				return;

			this.setUser(r.item);
		});
	}

	login(body){
		if(this.logingIn)
			this.logingIn.abort();

		this.logingIn = this.authApi._post('/login', {}, body);

		return this.logingIn.then(response => {
			delete this.logingIn;

			if(!response.success)
				return response;

			this._saveLogin(parseInt(response.expires) * 1000);
			this.emitLogin();
			return response;
		});
	}

	update(body = {}){
		if(this._updating){
			this._updating.abort();
		}

		return new Promise((resolve, reject) => {
			this._updating = this.userApi.updateSelf(body)

			this._updating
			.then(resolve, reject);
		});
	}

	register(body){
		return this.userApi._post('/register', {}, body);
	}

	_logout(){
		this.deleteExpires();
		this.deleteUser();
		clearTimeout(this.timerID);
	}

	fullLogout(){
		this._logout();

		this.emitLogout();
		if(this.logingOut) return;

		this.logingOut = this.authApi._post('/fulllogout');

		this.logingOut
		.then(response => { delete this.logingOut; })
		.catch(() => { delete this.logingOut; });
	}

	logout(){
		this._logout();

		this.emitLogout();

		if(this.logingOut) return;

		const pusherState = store.getState().pusher;

		const body = {}

		if(pusherState.endpoint.length > 0){
			body.endpoint = pusherState.endpoint;
		}

		this.logingOut = this.authApi._post('/logout', {}, body);

		this.logingOut
		.then(response => { delete this.logingOut; })
		.catch(() => { delete this.logingOut; });
	}

	getExpires(){
		return this._expires;
	}

	_setExpires(expires){
		this._expires = expires;
		this._setLogoutTimer();
		store.dispatch(setExpires(this._expires));
	}

	setExpires(expires){
		this._setExpires(expires);
		this.channel.postMessage({ action: 'expires', payload: this._expires });
	}

	deleteExpires(){
		this._expires = 0;
		store.dispatch(setExpires(this._expires));
	}

	getUser(){
		return this._user;
	}

	_setUser(user){
		this._user = user;
		store.dispatch(setUser(this._user));
	}

	setUser(user){
		this._setUser(user);
		this.channel.postMessage({ action: 'user', payload: this._user });
	}

	deleteUser(){
		this._user = {};
		store.dispatch(setUser(this._user));
	}

	_emitLogin(){
		this.loginHooks.forEach(h => h(true));
		store.dispatch(setLogged(true));
	}

	emitLogin(){
		this._emitLogin();
		this.channel.postMessage({ action: 'login' });
	}

	_emitLogout(){
		this.loginHooks.forEach(h => h(false));
		store.dispatch(setLogged(false));
	}

	emitLogout(){
		this._emitLogout();
		this.channel.postMessage({ action: 'logout' });
	}

	addLoginHook(hook){
		this.loginHooks.add(hook);
	}

	deleteLoginHook(hook){
		this.loginHooks.delete(hook);
	}
}

export const Auth = new _Auth();
