import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import { Text } from '../Text/Text';

const NullLink = React.memo(({children, ...props}) => {
	return (
		<span {...props}>
			{ children }
		</span>
	);
});

export const _DropDownMenuLink = function({text, icon, ...props}){
	const match = useRouteMatch({ path: props.to, exact : props.exact });
	
	const WrapperEl = useRouteMatch({ path: props.to, exact : true }) ? NullLink : Link;
	
	return (
		<WrapperEl {...props} className={`dropdown-item${match ? ' active' : ''}`}>
			<Text text={text} />
		</WrapperEl>
	);
};

export const DropDownMenuLink = React.memo(_DropDownMenuLink);